<template>
  <div class="content">
    <div class="searchWrapper">
      <!--（表单）选项区域-->
      <el-form ref="form" :model="form" label-width="100px">
        <!--选项区域第一行-->
        <div class="optionAreaOne">
          <el-form-item label="退款订单号:">
            <el-input
              v-model="formInline.input1"
              placeholder="请输入内容"
              style="width: 180px"
              maxlength="19"
            ></el-input>
          </el-form-item>
          <el-form-item label="停车场名称:">
            <my-component
              ref="parkInput"
              :areaIds="formInline.streetId ? formInline.streetId : formInline.areaId"
              :operationId="formInline.operationId"
              @valueChange="completeValue"
              slaveRelations="0,1"
            >
            </my-component>
          </el-form-item>
          <el-form-item label="车牌号:">
            <el-autocomplete
              ref="plateNumber"
              size="11"
              valueKey="plateNumber"
              class="inline-input"
              v-model="formInline.plateNumber"
              :fetch-suggestions="querySearchAsync"
              placeholder="车牌号"
              :trigger-on-focus="false"
              @select="handleSelect"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item label="申请人:">
            <el-autocomplete
              class="inline-input"
              v-model="formInline.bossManagerName"
              :fetch-suggestions="querySearchAsync1"
              placeholder="请输入内容"
              value-key="bossManagerName"
              :trigger-on-focus="false"
              @select="handleSelect1"
            ></el-autocomplete>
          </el-form-item>
          <!-- <el-form-item label="退款类型:">
            <el-select v-model="formInline.refundType" placeholder="请选择">
              <el-option
                v-for="item in refundTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item> -->
        </div>
        <!--选项区域第二行-->
        <div class="optionAreaThree">
          <el-form-item label="申请时间:">
            <!-- <el-date-picker v-model="date1"
                            type="datetime"
                            placeholder="选择日期"> </el-date-picker> 至
            <el-date-picker style="margin-right:10px;"
                            v-model="date2"
                            type="datetime"
                            placeholder="选择日期"> </el-date-picker> -->
            <timeRangePick
              @timeChange="timeChange"
              ref="timeRangePicker"
              style="margin-right: 10px"
              :defalutDate="defalutDate"
            />
          </el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="
              pageNum = 1;
              searParkRecordList();
            "
            :loading="loading"
            class="but"
            >{{ $t('button.search') }}</el-button
          >
        </div>
      </el-form>
    </div>
    <!--列表-->
    <div class="tableWrapper paddingB20">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
        <el-table-column align="center" label="操作" width="">
          <template slot-scope="scope">
            <el-button type="text" @click="passAgo(scope)" v-if="$route.meta.authority.button.agree"
              >通过</el-button
            >
            <el-button
              type="text"
              @click="rejectAgo(scope)"
              style="color: red"
              v-if="$route.meta.authority.button.reject"
              >驳回</el-button
            >
            <el-button
              type="text"
              @click="fn(scope)"
              style="color: gray"
              v-if="$route.meta.authority.button.detail"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!--通过弹窗-->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>确认通过该订单?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="passFn">确 定</el-button>
      </span>
    </el-dialog>
    <!--驳回弹窗-->
    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="30%" center>
      <label>驳回原因：</label>
      <el-input v-model="windowinput" placeholder="请输入内容" style="width: 300px"></el-input>
      <p class="warning" v-show="switcher"><b>原因不能为空!</b></p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="rejectFn">确 定</el-button>
      </span>
    </el-dialog>
    <!--详情弹窗-->
    <el-dialog title="退款审批" :visible.sync="detailsWindow" width="80%">
      <el-steps
        :space="200"
        :active="1"
        finish-status="success"
        style="margin-left: 24%"
        align-center
      >
        <el-step title="申请退款"></el-step>
        <el-step title="审批退款"></el-step>
        <el-step title="确认退款"></el-step>
      </el-steps>
      <h1 class="titleh1">停车记录</h1>
      <el-table :data="tableData4" style="width: 100%">
        <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols4"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
      </el-table>

      <h1 class="titleh1">支付记录</h1>
      <el-table :data="tableData3" style="width: 100%">
        <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols2"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
      </el-table>
      <!--<h1 class="titleh1">退款申请1</h1>-->
      <!--<el-table border-->
      <!---->
      <!--:data="tableData2"-->
      <!--style="width: 100%">-->
      <!--<el-table-column align='center'-->
      <!--:prop="item.prop"-->
      <!--:label="item.label"-->
      <!--v-if="item.prop != 'actualExitTime' || showParkRecord"-->
      <!--:width="item.width"-->
      <!--v-for="item in tableCols3"-->
      <!--:key="item.prop"-->
      <!--:formatter="item.formatter"></el-table-column>-->
      <!--</el-table>-->
      <el-form ref="form" :model="form" label-width="100px" class="recordForm">
        <el-form-item label="退款原因:">
          <span>{{ form.refundReasonName }}</span>
        </el-form-item>
        <el-form-item label="退款方式:">
          <span>原路退回</span>
        </el-form-item>
        <!--实际出场时间-->
        <el-form-item label="实际出场时间:" v-show="form.region === 0">
          <span>{{ form.exitTime }}</span>
        </el-form-item>
        <el-form-item label="退款计算:">
          <el-table :data="moneyShow" style="width: 100%">
            <el-table-column align="center" label="">
              <template slot-scope="scope">
                <p v-show="scope.$index == 0">原订单</p>
                <p v-show="scope.$index == 1">修改后</p>
                <p v-show="scope.$index == 2">退款</p>
                <!--<p>原订单</p>-->
                <!--<p>修改后</p>-->
                <!--<p>退款</p>-->
              </template>
            </el-table-column>
            <el-table-column align="center" prop="date" label="应付金额">
              <template slot-scope="scope">
                <p v-if="form.refundReason == 0 && scope.$index == 0">
                  {{
                    scope.row.shouldPayMoney ? (scope.row.shouldPayMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <p v-if="form.refundReason == 0 && scope.$index == 1">
                  {{
                    scope.row.shouldPayMoney != undefined
                      ? (scope.row.shouldPayMoney / 100).toFixed(2)
                      : "0.00"
                  }}
                </p>
                <p v-if="form.refundReason == 1 && scope.$index == 1">0.00</p>
                <!--<p v-if="form.region == 1 && scope.$index == 2">{{scope.row.shouldPayMoney ? (scope.row.shouldPayMoney / 100).toFixed(2) : ''}}</p>-->
                <p v-if="form.refundReason == 1 && scope.$index == 0">
                  {{
                    scope.row.shouldPayMoney ? (scope.row.shouldPayMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <p v-if="form.refundReason == 2 && scope.$index == 0">
                  {{
                    scope.row.shouldPayMoney ? (scope.row.shouldPayMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <!--<input style="width: 80%" type="text" v-model="scope.row.shouldPayMoney" v-if="form.region === 2 && scope.$index === 2">-->
              </template>
            </el-table-column>
            <el-table-column align="center" prop="name" label="停车卡抵扣">
              <template slot-scope="scope">
                <p v-if="form.refundReason == 0 && scope.$index == 0">
                  {{
                    scope.row.parkCardMoney ? (scope.row.parkCardMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <p v-if="form.refundReason == 0 && scope.$index == 1">
                  {{
                    scope.row.parkCardMoney != undefined
                      ? (scope.row.parkCardMoney / 100).toFixed(2)
                      : "0.00"
                  }}
                </p>
                <p v-if="form.refundReason == 0 && scope.$index == 2">
                  {{
                    currentChargeVO.parkCardMoney != undefined
                      ? Number(
                          (originalChargeVO.parkCardMoney - currentChargeVO.parkCardMoney) / 100
                        ).toFixed(2)
                      : "0.00"
                  }}
                </p>
                <p v-if="form.refundReason == 1 && scope.$index == 1">0.00</p>
                <p v-if="form.refundReason == 1 && scope.$index == 2">
                  {{
                    scope.row.parkCardMoney ? (scope.row.parkCardMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <p v-if="form.refundReason == 1 && scope.$index == 0">
                  {{
                    scope.row.parkCardMoney ? (scope.row.parkCardMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <p v-if="form.refundReason == 2 && scope.$index == 0">
                  {{
                    scope.row.parkCardMoney ? (scope.row.parkCardMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <p v-if="form.refundReason == 2 && scope.$index == 2">
                  {{ otherReason.parkCardMoney }}
                </p>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="address" label="优惠券金额">
              <template slot-scope="scope">
                <!--<p v-if="form.region !== 2|| scope.$index !== 2">{{ scope.row.couponMoney }}</p>-->
                <p v-if="form.refundReason == 0 && scope.$index == 0">
                  {{ scope.row.couponMoney ? (scope.row.couponMoney / 100).toFixed(2) : "0.00" }}
                </p>
                <p v-if="form.refundReason == 0 && scope.$index == 1">
                  {{
                    scope.row.couponMoney != undefined
                      ? (scope.row.couponMoney / 100).toFixed(2)
                      : "0.00"
                  }}
                </p>
                <p v-if="form.refundReason == 0 && scope.$index == 2">
                  {{
                    currentChargeVO.couponMoney != undefined
                      ? Number(
                          (originalChargeVO.couponMoney - currentChargeVO.couponMoney) / 100
                        ).toFixed(2)
                      : "0.00"
                  }}
                </p>
                <p v-if="form.refundReason == 1 && scope.$index == 1">0.00</p>
                <p v-if="form.refundReason == 1 && scope.$index == 2">
                  {{ scope.row.couponMoney ? (scope.row.couponMoney / 100).toFixed(2) : "0.00" }}
                </p>
                <p v-if="form.refundReason == 1 && scope.$index == 0">
                  {{ scope.row.couponMoney ? (scope.row.couponMoney / 100).toFixed(2) : "0.00" }}
                </p>
                <p v-if="form.refundReason == 2 && scope.$index == 0">
                  {{ scope.row.couponMoney ? (scope.row.couponMoney / 100).toFixed(2) : "0.00" }}
                </p>
                <p v-if="form.refundReason == 2 && scope.$index == 2">
                  {{ otherReason.couponMoney }}
                </p>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="address" label="车场折扣金额">
              <template slot-scope="scope">
                <p v-if="form.refundReason == 0 && scope.$index == 0">
                  {{
                    scope.row.discountMoney ? (scope.row.discountMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <p v-if="form.refundReason == 0 && scope.$index == 1">
                  {{
                    scope.row.discountMoney != undefined
                      ? (scope.row.discountMoney / 100).toFixed(2)
                      : "0.00"
                  }}
                </p>
                <p v-if="form.refundReason == 0 && scope.$index == 2">
                  {{
                    currentChargeVO.discountMoney != undefined
                      ? Number(
                          (originalChargeVO.discountMoney - currentChargeVO.discountMoney) / 100
                        ).toFixed(2)
                      : "0.00"
                  }}
                </p>
                <p v-if="form.refundReason == 1 && scope.$index == 0">
                  {{
                    scope.row.discountMoney ? (scope.row.discountMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <p v-if="form.refundReason == 1 && scope.$index == 1">0.00</p>
                <p v-if="form.refundReason == 1 && scope.$index == 2">
                  {{
                    scope.row.discountMoney ? (scope.row.discountMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <p v-if="form.refundReason == 2 && scope.$index == 0">
                  {{
                    scope.row.discountMoney ? (scope.row.discountMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <p v-if="form.refundReason == 2 && scope.$index == 2">
                  {{ otherReason.discountMoney }}
                </p>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="address" label="实付金额">
              <template slot-scope="scope">
                <p v-if="form.refundReason == 0 && scope.$index == 0">
                  {{ scope.row.money ? (scope.row.money / 100).toFixed(2) : "0.00" }}
                </p>
                <p v-if="form.refundReason == 0 && scope.$index == 1">
                  {{ scope.row.money != undefined ? (scope.row.money / 100).toFixed(2) : "" }}
                </p>
                <p v-if="form.refundReason == 0 && scope.$index == 2">
                  {{
                    currentChargeVO.money != undefined
                      ? Number((originalChargeVO.money - currentChargeVO.money) / 100).toFixed(2)
                      : ""
                  }}
                </p>
                <p v-if="form.refundReason == 1 && scope.$index == 0">
                  {{ (scope.row.money / 100).toFixed(2) }}
                </p>
                <p v-if="form.refundReason == 1 && scope.$index == 1">0.00</p>
                <p v-if="form.refundReason == 1 && scope.$index == 2">
                  {{ scope.row.money ? (scope.row.money / 100).toFixed(2) : "0.00" }}
                </p>
                <p v-if="form.refundReason == 2 && scope.$index == 0">
                  {{ scope.row.money ? (scope.row.money / 100).toFixed(2) : "0.00" }}
                </p>
                <p v-if="form.refundReason == 2 && scope.$index == 2">{{ otherReason.money }}</p>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="备注:">
          <!-- <span>{{form.date4}}</span> -->
          <span>{{ form.remarks }}</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="detailePass" v-if="$route.meta.authority.button.agree"
          >通 过</el-button
        >
        <el-button
          type="primary"
          @click="detaileRejectAgo"
          v-if="$route.meta.authority.button.reject"
          >驳 回</el-button
        >
        <el-button @click="detailsWindow = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
import { setIndex, dateFormat } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
export default {
  name: "examine",
  data() {
    return {
      defalutDate: [],
      pageNum: 1,
      total: 0,
      rowData3: "",
      showParkRecord: false,
      pass: [],
      reject: [],
      details: [],
      tableData3: [],
      moneyShow: [],
      currentChargeVO: "",
      originalChargeVO: "",
      rowData: 0,
      loading: false,
      tableData: [],
      pageSize: 15,
      dialogVisible: false,
      centerDialogVisible: false,
      windowinput: "",
      switcher: false,
      detailsWindow: false,
      tableData2: [],
      tableData4: [],
      otherReason: {},
      payTypeStatus: [],
      form: {
        name: "",
        region1: "",
        region2: "",
        region3: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        value4: "",
      },
      formInline: {
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        parkType: "-1",
        parkName: "",
        plateNumber: "",
        entryOrExit: 1,
        mobile: "",
        refundRecordId: "",
        input1: "",
        startTime: "",
        endTime: "",
        // refundType: ''   // 待定功能，未联调
      },
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "orderNumber",
          label: "退款订单号",
          width: "120",
        },
        {
          prop: "parentParkName",
          label: this.$t("list.park_name"),
          width: "120",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "120",
        },
        // {
        //   prop: 'refundType',
        //   label: '退款类型',
        //   width: '120'
        // },
        {
          prop: "entryTime",
          label: this.$t("list.entry_time"),
          formatter: (row) => {
            if (row.entryTime) {
              return dateFormat(new Date(row.entryTime * 1), "yyyy-MM-dd HH:mm:ss");
            } else {
              return "";
            }
          },
        },
        {
          prop: "exitTime",
          label: this.$t("list.Appearance_time"),
          formatter: (row) => {
            if (row.exitTime) {
              return dateFormat(new Date(row.exitTime * 1), "yyyy-MM-dd HH:mm:ss");
            } else {
              return "";
            }
          },
        },
        {
          prop: "orderShouldPay",
          label: this.$t("list.order_amount"),
          width: "70",
          formatter: (row, column) => {
            if (row.orderShouldPay) {
              return Number(row.orderShouldPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "actualRefundPay",
          label: this.$t("list.refund_amount"),
          width: "70",
          formatter: (row, column) => {
            if (row.actualRefundPay) {
              return Number(row.actualRefundPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "operatorName",
          label: this.$t("list.applicant"),
          width: "",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Application_Time"),
          width: "100",
        },
        {
          prop: "refundReasonName",
          label: this.$t("list.refund_reason"),
          width: "70",
        },
        {
          prop: "actualExitTime",
          label: this.$t("list.Actual_appearance_time"),
          width: "100",
          formatter: (row) => {
            return row.actualExitTime ? dateFormat(new Date(row.actualExitTime * 1)) : "";
          },
        },
      ],
      tableCols2: [
        {
          prop: "payOrderId",
          label: "订单编号",
          width: "",
        },
        {
          // payTypeStatus
          prop: "payType",
          label: "支付方式",
          width: "120",
          formatter: (row, column) => {
            for (let i = 0; i < this.payTypeStatus.length; i++) {
              if (this.payTypeStatus[i].payType === row.payType) {
                return this.payTypeStatus[i].payTypeName;
              }
            }
          },
        },
        // todo
        {
          prop: "shouldPay",
          label: "应付金额",
          width: "120",
          formatter: (row, column) => {
            if (row.shouldPay) {
              return Number(row.shouldPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "agioPay",
          label: this.$t("list.preferential_amount"),
          width: "120",
          formatter: (row, column) => {
            if (row.agioPay) {
              return Number(row.agioPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "money",
          label: this.$t("list.Actual_received_amount"),
          width: "120",
          formatter: (row, column) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "payTime",
          label: this.$t("list.payment_time"),
          width: "160",
        },
        {
          prop: "paymentId",
          label: "支付流水号",
          width: "",
        },
      ],
      tableCols3: [
        {
          prop: "refundModeName",
          label: this.$t("list.Refund_method"),
          width: "",
        },
        {
          prop: "payType",
          label: "支付方式",
          width: "120",
          formatter: (row, column) => {
            for (let i = 0; i < this.payTypeStatus.length; i++) {
              if (this.payTypeStatus[i].code === row.payType) {
                return this.payTypeStatus[i].desc;
              }
            }
          },
        },
        {
          prop: "actualRefundPay",
          label: this.$t("list.refund_amount"),
          width: "120",
          formatter: (row, column) => {
            if (row.actualRefundPay) {
              return Number(row.actualRefundPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "refundReasonName",
          label: this.$t("list.refund_reason"),
          width: "160",
        },
        {
          prop: "actualExitTime",
          label: this.$t("list.Actual_appearance_time"),
          formatter: (row) => {
            return row.actualExitTime ? dateFormat(new Date(row.actualExitTime * 1)) : "";
          },
        },
        {
          prop: "remarks",
          label: "备注",
          width: "100",
        },
        {
          prop: "operatorName",
          label: this.$t("list.applicant"),
          width: "100",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Application_Time"),
          width: "100",
        },
        {
          prop: "refundStateName",
          label: this.$t("list.state"),
          width: "100",
        },
      ],
      tableCols4: [
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row, column) => {
            if (row.plateNumber.indexOf("无") != -1) {
              return "无牌车";
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
          width: "",
        },
        {
          prop: "strEntryTime",
          label: this.$t("list.entry_time"),
          width: "",
        },
        {
          prop: "strExitTime",
          label: this.$t("list.leaving_time"),
          width: "",
        },
        {
          prop: "parkTime",
          label: this.$t("list.Parking_duration"),
          width: "",
        },
        {
          prop: "actualPay",
          label: "已收金额",
          width: "100",
          formatter: (row, column) => {
            if (row.actualPay) {
              return Number(row.actualPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
      ],
      refundTypeList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "手动申请退款",
          value: "1",
        },
        {
          label: "系统申请退款",
          value: "2",
        },
      ],
    };
  },
  watch: {
    tableData2: {
      handler(curVal, oldVal) {
        if (curVal.length > 0) {
          this.showParkRecord = this.tableData2[0].refundReasonName == "出场延时";
        }
      },
      deep: true,
    },
  },
  methods: {
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searParkRecordList();
    },
    querySearchAsync1(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      this.$axios
        .get("/acb/2.0/bossManager/list", {
          data: {
            page: 0,
            size: 20,
            bossManagerName: queryString,
          },
        })
        .then((res) => {
          if (res.value.list.length > 0) {
            // this.formInline.operator = res.value.list[0].bossManagerName;
            this.formInline.bossManagerId = res.value.list[0].bossManagerId;
          } else {
            this.formInline.bossManagerId = "";
          }
          cb(res.value.list);
        });
    },
    handleSelect1(item) {
      this.formInline.bossManagerId = item.bossManagerId;
      this.formInline.bossManagerName = item.bossManagerName;
    },
    onSubmit() {},
    fn(scope) {
      this.detailsWindow = true;
      this.tableData2 = [];
      this.tableData2.push(scope.row);
      this.rowData = scope.row.payOrderId;
      this.rowData3 = scope.row.parkRecordId;
      this.getData();
      this.refundRecordIdFn();
      this.refundRecordDetail(scope.row.refundRecordId);
    },
    refundRecordDetail(scope) {
      this.moneyShow = [];
      this.$axios.get("/acb/2.0/refundRecord/getById/" + scope).then((res) => {
        this.form = res.value;
        this.currentChargeVO = res.value.currentChargeVO;
        this.originalChargeVO = res.value.originalChargeVO;
        this.moneyShow.push(res.value.originalChargeVO);
        this.moneyShow.push(res.value.currentChargeVO);
        this.moneyShow.push(res.value.originalChargeVO);
        if (this.form.refundReason == 2) {
          this.otherReason.money = Number(res.value.actualRefundPay / 100).toFixed(2); // 实付金额
          this.otherReason.discountMoney = Number(res.value.parkDiscountRefundPay / 100).toFixed(2); // 车场折扣金额
          this.otherReason.parkCardMoney = Number(res.value.parkCardRefundPay / 100).toFixed(2); // 停车卡抵扣金额
          this.otherReason.couponMoney = Number(res.value.agioRefundPay / 100).toFixed(2); // 优惠券金额
          this.moneyShow.splice(2, 1, this.otherReason);
        }
      });
    },
    // 停车记录
    refundRecordIdFn() {
      this.$axios.get("/acb/2.0/parkRecord/" + this.rowData3).then((res) => {
        this.tableData4 = [];
        this.tableData4.push(res.value);
      });
    },
    getData() {
      this.$axios
        .get("/acb/2.0/refundRecord/getpayOrderById?payOrderId=" + this.rowData)
        .then((res) => {
          this.tableData3 = [];
          this.tableData3.push(res.value);
        });
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    areaChange(item) {
      this.$refs.parkInput.setValue("");
      this.formInline.operationId = "";
      if (!item) {
        this.formInline.streetId = "";
        this.areaList2 = [];
        return;
      }
      this.getChildrenArea(item);
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    handleSelect(item) {
      this.loading = false;
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searParkRecordList();
    },
    // 搜索
    searParkRecordList() {
      if (this.formInline.bossManagerName == "") {
        this.formInline.bossManagerId = "";
      }
      let flag = this.showLog();
      if (flag) {
        this.loading = true;
        this.flag = false;
        this.$refs.parkInput.setShowVal(this.formInline.parkName);
        this.searParkRecordListFun();
      }
    },
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    searParkRecordListFun() {
      let opt = {
        method: "get",
        url: "/acb/2.0/refundRecord/list",
        data: {
          page: this.pageNum,
          size: this.pageSize,
          refundState: 1,
          // 退款订单号 orderNumber
          orderNumber: this.formInline.input1,
          // 车牌号 plateNumber
          plateNumber: this.formInline.plateNumber,
          startTime: this.formInline.startTime,
          endTime: this.formInline.endTime,
          operator: this.formInline.bossManagerId,
          carId: this.formInline.carId,
          parentParkId: this.formInline.parkId,
          // refundType: this.formInline.refundType
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 通过弹窗
    handleClose(done) {
      this.dialogVisible = true;
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    passAgo(scope) {
      this.dialogVisible = true;
      this.pass = [];
      this.pass.push(scope.row);
    },
    passFn() {
      this.detailsWindow = false;
      this.dialogVisible = false;
      this.$axios
        .post("/acb/2.0/refundRecord/updateRefundAndApproval", {
          data: {
            // id
            refundRecordId: this.pass[0].refundRecordId,
            // 备注
            // remarks: this.pass[0].remarks,  // jira TOGAC-15499  修复bug  注释
            remarks: "",
            // 同意
            isAgree: 1,
          },
        })
        .then((res) => {
          setTimeout((_) => {
            this.searParkRecordListFun();
          }, 300);
        });
    },
    rejectAgo(scope) {
      this.switcher = false;
      this.centerDialogVisible = true;
      this.reject = [];
      this.reject.push(scope.row);
    },
    detailePass() {
      this.dialogVisible = true;
      this.pass = this.tableData2;
    },
    detaileRejectAgo() {
      this.centerDialogVisible = true;
      this.reject = this.tableData2;
    },
    rejectFn() {
      this.detailsWindow = false;
      if (this.windowinput) {
        this.centerDialogVisible = false;
        this.$axios
          .post("/acb/2.0/refundRecord/updateRefundAndApproval", {
            data: {
              // id
              refundRecordId: this.reject[0].refundRecordId,
              // 备注
              remarks: this.windowinput,
              // 驳回
              isAgree: 2,
            },
          })
          .then((res) => {
            setTimeout((_) => {
              this.searParkRecordListFun();
            }, 300);
          });
      } else {
        this.switcher = true;
      }
    },
    payType() {
      this.$axios.get("/acb/2.0/payment/payTypeDictList").then((res) => {
        this.payTypeStatus = res.value;
      });
    },
  },
  created() {
    this.payType();
    this.defalutDate = [this.dataTimeRest() + " 00:00:00", this.dataTimeRest() + " 23:59:59"];
  },
  components: {
    myComponent,
    timeRangePick,
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.searchWrapper
  margin-bottom 20px
.pagerWrapper
  text-align right
  margin-top 28px
  font-size 12px
.el-form
  padding-top 23px
.optionAreaOne
  display flex
.optionAreaThree
  display flex
.title
  margin-top 30px
.warning
  color red
  width 100%
  text-align center
  margin-top 10px
.titleh1
  margin 10px 10px
  font-size 15px
.but
  height 40px
  margin-left 15px
  text-align center
</style>
